import { createStyles, makeStyles } from '@mui/styles';
import { Box, Collapse, Grid, Theme, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Plan,
  PlanFrequency,
  StripeCoupon,
  StudentCredentialDataInput,
  StudentCredentialInput,
  Subscription,
  SubscriptionBillingInput,
  useValidateCouponLazyQuery,
} from '../../../shared/types/generated';
import { useNavigate, useParams } from 'react-router-dom';
import { changePlanStoreAction } from '../payment-actions';
import { changePlanStore } from '../payment-events';
import { useStore } from '@cobuildlab/react-simple-state';
import { PaymentSelection } from '../../../shared/constans';
import { Paypal } from '../../paypal/Paypal';
import { useAuth } from '../../auth/auth-hook';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { PRIMARY_COLOR } from '../../../shared/css/theme';
import { SelectDefault } from '../../../shared/components/input/SelectDefault';
import { BILLING_INFO } from '../payment-constants';
import { openDialogAlert } from '../../alert/alert-actions';
import { USPeso } from '../../plan/components/PlanNewCard';
import { StripeButton } from '../../stripe/Stripe';
import {
  ShieldOutlined as ShieldIcon,
  SellOutlined as OfferIcon,
} from '@mui/icons-material';
import { InputDefault } from '../../../shared/components/InputDefault';
import { ShippingInfo } from './ShipingInfo';
import { couponDiscountAmount } from '../../coupons/coupon-utils';
import { StudentCredentialInfo } from './StudentCredentialInfo';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #999999',
      color: '#999999',
    },

    subtitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    box: {
      borderRadius: 5,
      boxShadow: '0px 3px 25px #00000012',
      justifyContent: 'center',
      border: '1px solid #999999',
      padding: '20px 30px',
      [theme.breakpoints.down('md')]: {
        padding: '20px 10px',
      },
    },
    contentCard: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20,
    },
    card: {
      width: 150,
      borderRadius: 5,
      height: 90,
      display: 'flex',
      boxShadow: '0px 3px 25px #00000012',
      justifyContent: 'center',
      cursor: 'pointer',
      alignItems: 'center',
      margin: '0 5px',
    },

    cartText: { fontSize: 15, lineHeight: 15, marginLeft: 10 },
    notification: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '16px',
      border: '1px solid #999999',
    },
  }),
);

type PaymentMethodCardProps = {
  items?: Plan[] | null;
};

const PAYMENT_ITEMS = [
  {
    selected: PaymentSelection.Stripe,
    src: '/card.svg',
    text: 'Tarjeta',
  },
  {
    selected: PaymentSelection.PayPal,
    src: '/paypal.svg',
    text: 'PayPal',
  },
];
export const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({
  items = [],
}) => {
  const classes = useStyles();
  const { slug } = useParams<{ slug: string }>();
  const [selected, setSelected] = useState<PaymentSelection>(
    PaymentSelection.Stripe,
  );
  const [subscriptionBilling, setSubscriptionBilling] =
    useState<SubscriptionBillingInput>(BILLING_INFO);
  const [studentCredential, setStudentCredential] =
    useState<StudentCredentialInput>({ file: null });
  const [studentCardFile, setStudentCardFile] = useState<File | null>(null);
  const [showInputCoupon, setShowInputCoupon] = useState(false);
  const [couponValue, setCouponValue] = useState('');
  const [coupon, setCoupon] = useState<StripeCoupon | null>(null);

  const { plan } = useStore(changePlanStore);
  const { user } = useAuth();
  const navigate = useNavigate();
  const subscriptionActive = user?.subscriptions?.find(
    (item) => item?.status === 'ACTIVE',
  );

  const [callFetchCoupon, { loading: loadingCoupon }] =
    useValidateCouponLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted(data) {
        const couponData = data.validateCoupon;
        if (!couponData?.plan || couponData?.plan.id !== plan?.id) {
          openDialogAlert('El cupón no es válido para este plan');
          return;
        }
        if (couponData.status === 'INACTIVE') {
          openDialogAlert('El cupón no es válido');
        }
        openDialogAlert('Cupón aplicado correctamente');
        setCoupon(couponData);
      },
      onError(error) {
        console.error('Error:', error.message);
        openDialogAlert('El cupón no es válido');
      },
    });

  useEffect(() => {
    setShowInputCoupon(false);
    setCoupon(null);
    setCouponValue('');
  }, [plan]);

  useEffect(() => {
    if (subscriptionActive) {
      const planWithoutActive = items?.filter(
        (item) => item.id !== subscriptionActive?.plan?.id,
      );

      setSelected(
        subscriptionActive.payment_method as unknown as PaymentSelection,
      );

      if (planWithoutActive?.length) {
        changePlanStoreAction(planWithoutActive[planWithoutActive?.length - 1]);
        return;
      }
    }
    if (slug && items) {
      const selectedPlan = items?.find((item) => item.slug === slug);
      changePlanStoreAction(selectedPlan);
    } else {
      console.log('ERROR');
    }
  }, [slug, items, subscriptionActive]);

  const changePaymentMethod = (paymentSelection: PaymentSelection): void => {
    const subscriptionBillingChecked = Object.keys(subscriptionBilling).find(
      (key) =>
        !subscriptionBilling[key as keyof SubscriptionBillingInput] &&
        key !== 'phone1',
    );

    if (
      paymentSelection === PaymentSelection.PayPal &&
      subscriptionBillingChecked &&
      plan?.subscription_billing_is_required
    ) {
      return openDialogAlert(
        'Los datos de envio son obligatorios para el plan anual',
      );
    }
    return setSelected(paymentSelection);
  };

  if (!user?.email) {
    return (
      <Box className={classes.notification}>
        <Typography variant="subtitle1" fontWeight={700} mb={1}>
          Para poder disfrutar de nuestros servicios agrega tu correo
          electrónico
        </Typography>
        <Typography variant="subtitle2" color="primary" mb={1}>
          Actualmente facebook cambio sus políticas de privacidad y limita la
          información de tu cuenta.
        </Typography>
        <ButtonDefault onClick={() => navigate('/')}>
          IR AL PERFIL
        </ButtonDefault>
      </Box>
    );
  }

  const discount = couponDiscountAmount(
    coupon,
    parseInt(plan?.price as string),
  );

  return (
    <>
      <Box className={classes.box} mb={2}>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.title} mb={2}>
              <Typography fontSize={18}>Información detallada</Typography>
              <Typography fontSize={18}>Total</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.subtitle}>
              <SelectDefault
                variant="outlined"
                options={items?.map((item) => ({
                  label: item.title as string,
                  value: item.slug?.toString() as string,
                  disabled: subscriptionActive?.plan?.id === item.id,
                }))}
                name={'plan'}
                value={(plan?.slug?.toString() as string) ?? slug}
                onChangeData={(field, value) => {
                  navigate(`/metodos-de-pago/${value}`);
                }}
                size="small"
              />
              <Box display="flex" alignItems="center">
                <Typography fontWeight={700}>
                  MXN {USPeso.format(Number(plan?.price) - discount)}{' '}
                  {plan?.frequency === PlanFrequency.Year ? '/año' : '/mes'}
                </Typography>
                {coupon ? (
                  <>
                    <Box mx={1}>{' - '}</Box>

                    <Typography
                      fontWeight={700}
                      style={{ textDecoration: 'line-through' }}>
                      MXN {USPeso.format(Number(plan?.price))}{' '}
                      {plan?.frequency === PlanFrequency.Year ? '/año' : '/mes'}
                    </Typography>
                  </>
                ) : (
                  ''
                )}
              </Box>
            </Box>
            {subscriptionActive && (
              <Box display={'inline-flex'} alignItems={'center'} pt={2}>
                <ShieldIcon fontSize={'small'} htmlColor="gray" />
                <Typography
                  fontSize={12}
                  color={'gray'}
                  align="center"
                  alignItems="center">
                  Ya tienes una suscripción activa. Si deseas cambiar de plan,
                  por favor selecciona un nuevo plan.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box my={2}>
        {!subscriptionActive && (
          <Box display={'inline-flex'} alignItems={'center'}>
            <ShieldIcon fontSize={'small'} htmlColor="gray" />
            <Typography
              fontSize={12}
              color={'gray'}
              align="center"
              alignItems="center">
              ¿Tienes un cupón?{' '}
              <button
                style={{
                  color: PRIMARY_COLOR,
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  cursor: 'pointer',
                  fontSize: 'inherit',
                  fontFamily: 'inherit',
                }}
                onClick={() => setShowInputCoupon(!showInputCoupon)}>
                Haz clic aquí para introducir tu codigo.
              </button>
            </Typography>
          </Box>
        )}

        <Collapse in={showInputCoupon}>
          <Box className={classes.box} my={2}>
            <Grid container>
              <Grid item xs={12}>
                <Typography fontSize={14} color="grey">
                  Si tienes un cupón, por favor, aplicalo abajo.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  className={classes.subtitle}
                  sx={{
                    mt: 2,
                    gap: 2,
                    alignItems: 'flex-end',
                  }}>
                  <InputDefault
                    label="Código de cupón"
                    value={couponValue}
                    readOnly={!!coupon}
                    onChange={(e) => setCouponValue(e.target.value)}
                  />

                  <ButtonDefault
                    disabled={!couponValue || !!coupon}
                    isLoading={loadingCoupon}
                    onClick={() =>
                      callFetchCoupon({
                        variables: {
                          couponCode: couponValue,
                          plan_id: plan?.id as number,
                        },
                      })
                    }
                    sx={{
                      my: '5px',
                      px: 4,
                      borderRadius: 30,
                      whiteSpace: 'pre',
                      textTransform: 'none',
                    }}>
                    Aplicar cupón
                  </ButtonDefault>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {coupon && (
                  <Box display={'inline-flex'} mt={2}>
                    <OfferIcon fontSize={'small'} htmlColor="gray" />
                    <Typography fontSize={14} color="grey" ml="2px">
                      Cupón aplicado correctamente
                    </Typography>
                    <Typography fontSize={14} color="grey" pl="2px">
                      (
                      {coupon?.discount_percent && coupon.discount_percent > 0
                        ? `-${coupon.discount_percent}%`
                        : `-${USPeso.format(
                            Number(coupon.discount_amount),
                          )} MXN`}
                      )
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Box>
      {plan?.subscription_billing_is_required && !user.subscription_billing && (
        <Box className={classes.box} mb={2}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={10} md={7} lg={6}>
              <Typography pb={1} fontWeight={700} fontSize={'18px'}>
                Información de envío
              </Typography>
              <ShippingInfo
                subscriptionBilling={subscriptionBilling}
                onChange={(name, value) => {
                  setSubscriptionBilling({
                    ...subscriptionBilling,
                    [name as keyof SubscriptionBillingInput]: value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {plan?.subscription_student_credential_is_required && (
          <Box className={classes.box} mb={2}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={10} md={7} lg={6}>
                <Typography pb={1} fontWeight={700} fontSize={'18px'} textAlign="center" paddingBottom={2}>
                  Credenciales de estudiante
                </Typography>
                <StudentCredentialInfo
                  studentCredentialCardFile={studentCardFile}
                  onDrop={(file) => setStudentCardFile(file)}
                  studentCredential={studentCredential}
                  onChange={(name, value) => {
                    setStudentCredential({
                      ...studentCredential,
                      [name as keyof StudentCredentialDataInput]: value,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      <Box className={classes.box}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={10} md={7} lg={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  style={{ fontSize: 16, fontWeight: 'bold' }}
                  align={'center'}
                  mb={2}>
                  Elige un método de pago:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contentCard}>
                  {subscriptionActive
                    ? PAYMENT_ITEMS.filter(
                        (item) =>
                          item.selected ===
                          (subscriptionActive?.payment_method as unknown as PaymentSelection),
                      ).map((item) => (
                        <Box
                          onClick={() => changePaymentMethod(item.selected)}
                          className={classes.card}
                          style={{
                            border:
                              selected === item.selected
                                ? `1px solid ${PRIMARY_COLOR}`
                                : 'none',
                          }}>
                          <img
                            src={item.src}
                            alt="open pay"
                            width={28}
                            height={33}
                          />
                          <div className={classes.cartText}>{item.text}</div>
                        </Box>
                      ))
                    : PAYMENT_ITEMS.map((item) => (
                        <Box
                          onClick={() => changePaymentMethod(item.selected)}
                          className={classes.card}
                          style={{
                            border:
                              selected === item.selected
                                ? `1px solid ${PRIMARY_COLOR}`
                                : 'none',
                          }}>
                          <img
                            src={item.src}
                            alt="open pay"
                            width={28}
                            height={33}
                          />
                          <div className={classes.cartText}>{item.text}</div>
                        </Box>
                      ))}
                </div>
              </Grid>
              <Grid item xs={12}>
                {selected === PaymentSelection.Stripe && (
                  <StripeButton
                    studentCredential={{
                      file: studentCardFile,
                    }}
                    subscriptionBilling={subscriptionBilling}
                    coupon={coupon}
                    activeSubscription={subscriptionActive as Subscription}
                  />
                )}
                {selected === PaymentSelection.PayPal && (
                  <Paypal
                    subscriptionBilling={subscriptionBilling}
                    coupon={coupon}
                    activeSubscription={subscriptionActive as Subscription}
                    studentCredential={{
                      file: studentCardFile,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
