import { Box, Stack, Theme, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useAuth } from '../../auth/auth-hook';
import { getPostUrl } from '../../auth/auth-utils';
import {
  professionalPlanBenefits,
  standardPlanBenefits,
  studentsPlanBenefits,
} from './benefitsByPlan';
import { createStyles, makeStyles } from '@mui/styles';
import { Plan, PlanFrequency } from '../../../shared/types/generated';

type PlanProps = {
  plan: Plan;
  typePlan?: 'professional' | 'student' | 'standard';
  includePromo?: boolean;
};
//theme: Theme
const useStyles = makeStyles<Theme>(() =>
  createStyles({
    bannerPro: {
      backgroundImage: 'url("/profesional_plan_enero.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      margin: '0 auto',
    },
    bannerStudent: {
      backgroundImage: 'url("/estudiantil_plan_enero.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      margin: '0 auto',
    },
    bannerStandard: {
      backgroundImage: 'url("/estandar_plan_enero.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      margin: '0 auto',
    },
    backgroundPro: {
      background: '#2C2C2C',
      color: 'white',
      textTransform: 'uppercase',
    },
    backgroundStudent: {
      background: '#4052ED',
      color: 'white',
      textTransform: 'uppercase',
      boxShadow: '0 8px 8px -8px #000000',
    },
    backgroundStandard: {
      background: '#FF3C41',
      color: 'white',
      textTransform: 'uppercase',
    },
  }),
);
export const USPeso = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 3,
});
export const PlanNewCard: React.FC<PlanProps> = ({
  plan,
  typePlan,
  includePromo = false,
}) => {
  const { user } = useAuth();
  const classes = useStyles();
  let benefits: (string | ReactNode)[] = [];
  // let backgroundText = '';
  // let colorText = '';
  const backgroundclasses =
    typePlan === 'professional'
      ? classes.backgroundPro
      : typePlan === 'student'
      ? classes.backgroundStudent
      : classes.backgroundStandard;

  switch (typePlan) {
    case 'professional':
      benefits = professionalPlanBenefits;
      // backgroundText = '#E9E9E9';
      // colorText = '#616161';
      break;
    case 'standard':
      benefits = standardPlanBenefits;
      // backgroundText = '#FFE0E0';
      // colorText = '#FF8C8F';
      break;
    case 'student':
      benefits = studentsPlanBenefits;
      // backgroundText = '#DADDF3';
      // colorText = '#8D9AFB';
      break;
  }

  return (
    <Stack
      mb={2}
      width={'328px'}
      paddingBottom={'10px'}
      fontFamily={'acumin-pro'}
      borderRadius={'8px'}
      overflow={'hidden'}
      component={'div'}
      position={'relative'}
      style={{ boxShadow: '0px 3px 8px #00000029' }}
    >
      <Box
        height={'50px'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        className={backgroundclasses}
        sx={{
          boxShadow: '0 8px 10px -2px #0000005d !important',
          zIndex: '100',
        }}
      >
        <Typography
          fontFamily={'acumin-pro'}
          fontSize={'26px'}
          fontWeight={'bold !important'}
          lineHeight={'26px'}
          textAlign={'center'}
        >
          {plan.title_front}
        </Typography>
      </Box>
      <Box
        height={'140px'}
        width={'100%'}
        className={
          typePlan === 'professional'
            ? classes.bannerPro
            : typePlan === 'student'
            ? classes.bannerStudent
            : classes.bannerStandard
        }
      />

      {/**{plan.sub_title ? (
        <Typography
          fontFamily={'acumin-pro'}
          textAlign={'center'}
          lineHeight={'20px'}
          color={'#606060'}
          mt={'10px'}
          textTransform={'uppercase'}
        >
          {plan.sub_title}
        </Typography>
      ) : (
        <Box paddingBottom={'32px'}></Box>
      )} */}
      {plan.helper_text ? (
        <Typography
          fontFamily={'acumin-pro'}
          mt={'16px'}
          // mb={'8px'}
          textAlign={'center'}
          fontWeight={'bold'}
          fontSize={'18px'}
          lineHeight={'22px'}
        >
          <span style={{ textDecoration: 'line-through' }}>
            {plan.helper_text}
          </span>
          <span style={{ fontSize: '18px', textDecoration: 'none' }}>
            {plan.frequency === PlanFrequency.Year ? ' /año' : ' /mes'}
          </span>
        </Typography>
      ) : (
        <Box paddingTop={1}></Box>
      )}
      <Typography
        fontFamily={'acumin-pro'}
        // mt={'8px'}
        textAlign={'center'}
        fontWeight={'bold'}
        fontSize={'30px'}
        lineHeight={'30px'}
        color={'#FF3C41'}
      >
        {USPeso.format(Number(plan?.price))} MXN{' '}
        <span style={{ fontSize: '20px', color: '#FF3C41' }}>
          {plan.frequency === PlanFrequency.Year ? '/año' : '/mes'}
        </span>
      </Typography>
      <Box display={'flex'} fontFamily={'acumin-pro'} justifyContent={'center'}>
        <Typography
          mt={'8px'}
          fontFamily={'acumin-pro'}
          fontSize={'11px'}
          textAlign={'center'}
          color={'#606060'}
        >
          El pago por el monto indicado se realizará cada{' '}
          {plan.frequency === PlanFrequency.Year ? 'año' : 'mes'}.
        </Typography>
      </Box>
      {/* <Box
        paddingBottom={1}
        marginTop={1}
        paddingTop={1}
        paddingX={2}
        sx={{ background: backgroundText }}
      >
        <Typography
          color={colorText}
          textAlign={'center'}
          lineHeight={'30px'}
          fontSize={24}
          fontWeight={700}
        >
          ¡Recibe una <br /> membresía de regalo!
        </Typography>
      </Box> */}
      {/**<Box display={'flex'} fontFamily={'acumin-pro'} justifyContent={'center'}>
        <Box /**width={'240px'} >
        <Typography
        mt={'8px'}
        fontFamily={'acumin-pro'}
        fontSize={'11px'}
        textAlign={'center'}
        color={'#606060'}
      >
        El pago por el monto indicado se realizará cada{' '}
        {plan.frequency === PlanFrequency.Year ? 'año' : 'mes'}.
      </Typography>
    </Box>
  </Box> */}
      <Box my={'10px'}>
        {/* <Typography
          fontFamily={'acumin-pro'}
          textAlign={'center'}
          fontSize={'12px'}
          fontWeight={'bold'}
          color={'#606060'}
          my={'10px'}
        >
          Válido al 6 de enero de 2025.
        </Typography> */}
      </Box>
      <Box display={'flex'} mt={'0px'} mb={'5px'} justifyContent={'center'}>
        <Box
          component={'div'}
          role="button"
          tabIndex={0}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          style={{ background: '#FF3C41', cursor: 'pointer' }}
          width={'160px'}
          borderRadius={'20px'}
          height={'40px'}
          onClick={() => {
            if (user)
              return (window.location.href = `/metodos-de-pago/${
                plan.slug
              }?post_url=${getPostUrl()}`);
            else {
              const redirectUrl = `${window.location.origin}/metodos-de-pago/${
                plan.slug
              }?post_url=${getPostUrl()}`;
              window.location.href = `${
                window.location.origin
              }/autenticacion?redirect_url=${encodeURIComponent(redirectUrl)}`;
            }
          }}
        >
          <Typography
            fontFamily={'acumin-pro'}
            letterSpacing={'1px'}
            fontSize={'20px'}
            color={'#ffffff'}
            fontWeight={'bold'}
            textAlign={'center'}
          >
            Suscribirme
          </Typography>
        </Box>
      </Box>
      <ul
        style={{
          paddingLeft: '45px',
          paddingRight: '10px',
          margin: '15px 0px',
          height: '170px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          ...(includePromo && { justifyContent: 'initial' }),
        }}
      >
        {benefits.map((item, index) => (
          <li
            key={'list' + index}
            style={{ lineHeight: '18px', fontSize: '13px' }}
          >
            {item}
          </li>
        ))}
      </ul>
      {includePromo && (
        <>
          <Box height={'35px'} />
          <Box
            position={'absolute'}
            left={-1}
            bottom={-1}
            height={'103px'}
            width={'331px'}
          >
            <img
              src={'/promo-plan-de-los-muertos.png'}
              alt={'Promoción plan de los muertos'}
              width={'100%'}
              height={'100%'}
              style={{ objectFit: 'contain' }}
            />
          </Box>
        </>
      )}
      {/**<Typography
       fontSize={'12px'}
       color={'#515151'}
       textAlign={'center'}
       pb={'10px'}
       >
       Promoción de verano hasta el 30 de septiembre de 2024.
       </Typography> */}
    </Stack>
  );
};
