import React from 'react';
import { Header } from './Header';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Container, Grid, Theme, Typography } from '@mui/material';
import { Menu } from './Menu';
import { useAuth } from '../../../../modules/auth/auth-hook';
import { CardSection } from '../../card/CardSection';
import { Footer } from './Footer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      marginTop: '100px',
      marginBotom: '100px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '80px !important',
        marginBotom: '20px',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
  }),
);

type LayoutProps = {
  children: React.ReactNode;
  showSidebar?: boolean;
  showNotification?: boolean;
};
export const Layout: React.FC<LayoutProps> = ({
  children,
  showSidebar = true,
  showNotification = true,
}) => {
  const { user } = useAuth();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Header showSidebar={showSidebar} />
        <main className={classes.root}>
          <Container>
            <Grid container columnSpacing={2}>
              {!user?.email && showNotification && (
                <Grid item xs={12}>
                  <CardSection
                    title={
                      <Typography variant="subtitle1" fontWeight={700}>
                        Para poder disfrutar de nuestros servicios agrega tu
                        correo electrónico
                      </Typography>
                    }
                    subheader={
                      <>
                        <Typography variant="subtitle2" color="primary">
                          Actualmente facebook cambio sus políticas de
                          privacidad y limita la información de tu cuenta.
                        </Typography>
                      </>
                    }
                  />
                </Grid>
              )}

              {showSidebar && (
                <Grid item xs={12} md={4}>
                  <Box>
                    <Menu />
                  </Box>
                </Grid>
              )}

              <Grid item xs={12} md={showSidebar ? 8 : 12}>
                {children}
              </Grid>
            </Grid>
          </Container>
        </main>
        <Footer />
      </LocalizationProvider>
    </div>
  );
};
