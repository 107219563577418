import React, { FC, useRef } from 'react';
import { CardSection } from '../../shared/components/card/CardSection';
import { Box, Grid, IconButton, Link, Typography } from '@mui/material';
import { useAuth } from '../auth/auth-hook';
import { EditOutlined } from '@mui/icons-material';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { useCreateStudentCredentialMutation } from '../../shared/types/generated';
import { clientUpload } from '../../shared/apollo';
import { openDialogAlert } from '../alert/alert-actions';
import { parseToUrl } from '../../shared/utils';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export const StudentCredentialProfile: FC = () => {
  const { user, refetch } = useAuth();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = React.useState<File | null>(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [callCreateStudentCredential] = useCreateStudentCredentialMutation({
    client: clientUpload,
    refetchQueries: ['CurrentUser'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setIsEdit(false);
      openDialogAlert('Credencial estudiantil guardada correctamente');
      refetch();
    },
    onError: (error) => {
      setLoading(false);
      console.error(error);
      openDialogAlert('Error al guardar la credencial estudiantil');
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      setIsEdit(true);
      setFile(selectedFile);
    }
  };

  const renderFilePreview = (): JSX.Element => {
    if (file) {
      const fileType = file.type;

      if (fileType === 'application/pdf') {
        return (
          <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton
              component="a"
              href={URL.createObjectURL(file)}
              target="_blank"
              download={file.name}
              color="primary">
              <PictureAsPdfIcon fontSize="large" />
            </IconButton>
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              {file.name}
            </Typography>
          </Box>
        );
      } else if (fileType.startsWith('image/')) {
        return (
          <img
            src={URL.createObjectURL(file)}
            alt="Uploaded File"
            style={{
              maxWidth: '100%',
              maxHeight: '300px',
            }}
          />
        );
      }
    } else if (user?.student_credential?.student_card) {
      const storedFileUrl = parseToUrl(user.student_credential.student_card);

      const isPdf = storedFileUrl.endsWith('.pdf');

      if (isPdf) {
        const filename = decodeURIComponent(
          storedFileUrl.split('/').pop() ?? 'Descargar PDF',
        );
        return (
          <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton
              component="a"
              href={storedFileUrl}
              target="_blank"
              color="primary">
              <PictureAsPdfIcon fontSize="large" />
            </IconButton>
            <Link
              variant="subtitle1"
              underline="hover"
              sx={(theme) => ({
                ml: 1,
                cursor: 'pointer',
                color: 'black',
                '&:hover': {
                  color: theme.palette.primary.main,
                },
              })}
              href={storedFileUrl}
              target="_blank">
              {filename}
            </Link>
          </Box>
        );
      } else {
        return (
          <img
            src={storedFileUrl}
            alt="Student Credential"
            style={{
              maxWidth: '100%',
              maxHeight: '300px',
            }}
          />
        );
      }
    }

    return <Typography variant="subtitle1">No hay credencial</Typography>;
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CardSection
          title={
            <Typography variant="h6" fontWeight={800}>
              Detalles del perfil estudiantil
            </Typography>
          }>
          <Box p={2}>
            <Typography variant="subtitle1">
              Acá encontrarás información detallada de las credenciales
              estudiantiles.
            </Typography>
          </Box>
        </CardSection>
      </Grid>
      <Grid item xs={12}>
        <CardSection
          title={
            <Typography variant="h6" fontWeight={800}>
              Credenciales de estudiante
            </Typography>
          }
          actions={
            !isEdit ? (
              <Box
                mt={1}
                onClick={() => {
                  if (inputFileRef.current) {
                    inputFileRef.current.click();
                  }
                }}
                sx={{ cursor: 'pointer', px: 1 }}>
                <EditOutlined />
              </Box>
            ) : (
              <ButtonDefault
                isLoading={loading}
                onClick={() => {
                  if (file) {
                    setLoading(true);
                    callCreateStudentCredential({
                      variables: {
                        data: {
                          file,
                        },
                      },
                    });
                  }
                }}>
                Guardar
              </ButtonDefault>
            )
          }>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <input
              type="file"
              ref={inputFileRef}
              style={{ display: 'none' }}
              accept="image/*,application/pdf"
              onChange={handleFileChange}
            />
            {renderFilePreview()}
          </Box>
        </CardSection>
      </Grid>
    </Grid>
  );
};
