import React from 'react';
import { Box, Theme } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    itemSocialBar: {
      color: '#FFFFFF',
      cursor: 'pointer',
      textDecoration: 'none',
      height: '28px',
      width: '28px',
      // borderRadius: '100%',
      // background: 'white',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 10,
    },
    logosColor: {
      color: 'white',
    },
  }),
);

export const SocialBarNew: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      justifyContent={'flex-start'}
      alignItems={'center'}
      display={'flex'}
      component={'div'}
      style={{ background: '#000000' }}
      border={'1px solid black'}
      flexWrap={'wrap'}
      gap={3}>
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://twitter.com/pajaropolitico">
        <XIcon className={classes.logosColor} sx={{ height: '26px', width: '26px' }} />
      </Box>
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://www.facebook.com/pajaropolitico"
        fontWeight="bold">
        <img
          src="/facebook_icon.svg"
          alt="flipboard"
          width={'26px'}
          height={'26px'}
        />
      </Box>
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://www.youtube.com/user/pajaropolitico2012"
        fontWeight="bold">
        <YouTubeIcon className={classes.logosColor} fontSize="large" />
      </Box>
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://www.instagram.com/pajaropolitico/"
        fontWeight="bold">
        <InstagramIcon className={classes.logosColor} sx={{ height: '26px', width: '26px' }} />
      </Box>
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://flipboard.com/@AnimalPolitico"
        fontWeight="bold">
        <img
          src="/flipboard_white.svg"
          alt="flipboard"
          width={'26px'}
          height={'26px'}
        />
      </Box>
    </Box>
  );
};
